import React, {useEffect} from "react";

import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';
import Grow from "@mui/material/Grow";
import Zoom from '@mui/material/Zoom';
import {Theme, ThemeProvider} from "@mui/material/styles";
import {Badge, Card, ScopedCssBaseline} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import MuiMarkdown from "./component/MuiMarkdown";


type AppProps = {
    theme: Theme;
    urlOrigin: string;
};



function App({urlOrigin, theme}: AppProps) {

    const failureNotice = "Hey, I'm not feeling my best today. But I'll be back soon!"

    const [notificationCount, setNotificationCount] = React.useState<number>(0);
    const [viewMessage, setViewMessage] = React.useState<boolean>(false);
    const containerRef = React.useRef<HTMLElement>(null);
    const avatarRef = React.useRef<HTMLDivElement>(null);
    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

    const showMessage = () => {
        if(timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        setViewMessage(true)
        setNotificationCount(0)
        timeoutRef.current = setTimeout(()=>{
            setViewMessage(false)
            setNotificationCount(1)
        },10000)
    }


    useEffect(() => {
            window.parent.postMessage(
                {
                    type: 'RESIZE',
                    component: "BOT",
                    height: 'max(min(16vh,100px),82px)',
                    width: 'min(100px,27vw)'
                },
                urlOrigin
            );
    }, [urlOrigin]);


    useEffect(() => {
            window.parent.postMessage(
                {
                    type: 'RESIZE',
                    component: (viewMessage) ? "BOT_WITH_MESSAGE" : "BOT",
                    height: (viewMessage) ? (avatarRef.current?.offsetHeight || 0): 'max(min(16vh,100px),82px)',
                    width: (viewMessage) ? (avatarRef.current?.offsetWidth || 0): 'min(100px,27vw)'
                },
                urlOrigin
            );
    }, [urlOrigin,viewMessage]);




    return (
        <ThemeProvider theme={ theme}>
            <ScopedCssBaseline style={{padding: 0, margin: 0}}>
                    <div
                        ref={avatarRef}
                        style={{
                            minHeight: 100,
                            right: '0vh',
                            bottom: "0vh",
                            position: "fixed",
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-start'
                        }}>
                        {viewMessage && (
                            <div style={{paddingRight: 90}}>
                                <Box sx={{width: 200, height: '60%', overflow: 'hidden'}} ref={containerRef}>
                                    <Slide in={true} container={containerRef.current} direction={'left'}>
                                        <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                                            <Zoom in={true} style={{transformOrigin: 'bottom'}}>
                                                <Card
                                                    variant="outlined"
                                                    sx={{ml: 1}}
                                                    style={{
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        background: "rgba(246,246,246,1)",
                                                        borderRadius: 10,
                                                    }}
                                                >
                                                    <CardContent
                                                        style={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "20",
                                                            WebkitBoxOrient: "vertical",
                                                            paddingTop: 0,
                                                            marginTop: '-1em',
                                                            marginBottom: (failureNotice.length > 300) ? '0em' : '-1em',
                                                            paddingBottom: 0,
                                                            fontSize: "0.9em",
                                                            fontWeight: 400,
                                                            wordWrap: "break-word",
                                                            boxSizing: "content-box",
                                                        }}
                                                    >
                                                        <MuiMarkdown
                                                            markdownText={failureNotice}
                                                            markdownCustomCSS={{fontSize: "0.9em", lineHeight: "1.3"}}
                                                        />
                                                    </CardContent>
                                                </Card>
                                            </Zoom>
                                        </div>
                                    </Slide>
                                </Box>
                            </div>
                        )}

                        <Grow
                            in={true}
                            style={{
                                transformBox: "border-box",
                                marginRight:10,
                                marginBottom: 10,
                                alignContent: 'flex-end',
                                bottom: "0vh",
                                position: "fixed",
                            }}
                            timeout={1000}
                            addEndListener={()=>{
                                setTimeout(() => {
                                    showMessage()
                                },2000)
                            }}
                        >

                            <Button
                                variant="contained"
                                style={{borderRadius: 50, border: '4px solid', background: "rgb(38 40 46)"}}
                                onClick={()=>{showMessage()}}
                                sx={{
                                    padding: 0,
                                    position: "absolute",
                                    margin: 0

                                }}
                            >
                                <Badge color="primary" overlap="circular" badgeContent={notificationCount}>
                                    <Avatar
                                        sx={{
                                            width: 65,
                                            height: "auto",
                                        }}
                                        style={{aspectRatio: 1}}
                                        alt="botAvatarURL"
                                        src={ '/bot-avatar.gif'}
                                    />
                                </Badge>
                            </Button>
                        </Grow>
                    </div>
            </ScopedCssBaseline>
        </ThemeProvider>
    );
}

export default App;
