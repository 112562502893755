import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {createTheme} from "@mui/material";
import {validUrlOrNone} from "./util/helper";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);


//get params from url
const url = new URL(window.location.href);
const urlPath = url.searchParams.get("urlPath") || "";

const validatedUrl = validUrlOrNone(decodeURIComponent(urlPath))


if(validatedUrl){
    root.render(
        <React.StrictMode>
            <App urlOrigin={new URL(validatedUrl).origin} theme={createTheme()} />
        </React.StrictMode>
    );

} else{
    console.error(`Invalid url ${urlPath}`)
    // the url is invalid. Hence rejecting
    root.render(<></>)
}


