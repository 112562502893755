
import Markdown from 'react-markdown';
import {
    List,
    ListItem,
    ListItemText,
  } from "@mui/material";
import React from "react";


type MarkdownCustomCSS = {
    fontSize?: string,
    lineHeight?: string
    margin?: string|number
};

type MuiMarkdownProps = {
    markdownText: string, 
    markdownCustomCSS?: MarkdownCustomCSS
}

function markDownLinkRenderer(props:React.AnchorHTMLAttributes<HTMLAnchorElement>) {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  }

function MuiMarkdown({markdownText, markdownCustomCSS}: MuiMarkdownProps) {

    return <Markdown components={{a: markDownLinkRenderer,
        ol: ({ children }) => (<List sx={{
          listStyleType: "decimal",
          pt: 1,
          mt: 0,
          pl: 0,
          mb: 0,
          pb: 1,
          "& .MuiListItem-root": {
            display: "list-item",
          },
        }}>{children}</List>),
        ul: ({ children }) => (<List sx={{
          listStyleType: "disc",
          pt: 1,
          mt: 0,
          pl: 2,
          mb: 0,
          pb: 1,
          "& .MuiListItem-root": {
            display: "list-item",
          },
        }}>{children}</List>),
        li: ({ children, ...props }) => (
            <ListItem sx={{ m: 0, p: 0, ml: 2 }} disableGutters>
                <ListItemText sx={{ pr: 2 }}>
                    <span style={markdownCustomCSS}>{children}</span>
                </ListItemText>
            </ListItem>),    
      }}>
        {markdownText}
    </Markdown>

}


export default MuiMarkdown